<template>
  <div class="page" :loaded="Boolean(page)">
    <section class="top-section" v-if="page">
      <div class="wrapper">
        <div class="container" :style="{ backgroundImage: `url(${page.image})` }">
          <div class="content">
            <h1 v-html="page.title.replace(/\n/g, '<br>')"></h1>
            <h3 v-html="page.tagline.replace(/\n/g, '<br>')"></h3>
            <router-link class="btn white" to="/contact">Contact us</router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="section" v-if="page">
      <div class="wrapper">
        <div class="section-footer">
          <p v-html="page.description.replace(/\n/g, '<br>')"></p>
          <router-link class="btn medium" to="/contact">Get in touch!</router-link>
        </div>
      </div>
    </section>

    <section class="section incubation" v-if="incubators && incubators.length">
      <div class="wrapper">
        <div class="container">
          <div class="incubator" v-for="incubator in incubators" :key="incubator.id">
            <router-link :to="`/incubation/${incubator.seo.slug}`">
              <img :src="incubator.image" :alt="incubator.name" class="incubator-image">
            </router-link>
            <div class="incubator-content">
              <div class="incubator-title">
                <h5>{{incubator.name}}</h5>
                <div class="incubator-city">{{ incubator.city }}</div>
              </div>
              <p>{{incubator.excerpt}}</p>
              <div class="buttons">
                <router-link class="btn medium" :to="`/incubation/${incubator.seo.slug}`">
                  <span>Read more</span>
                </router-link>
                <!-- <a v-if="incubator.email" class="btn medium" :style="{ backgroundColor: incubator.color }" :href="`mailto:${incubator.email}`">
                  <span>Contact</span>
                </a> -->
                <a v-if="incubator.website" class="btn medium icon" target="_blank" :href="incubator.website">
                  <i class="ic ic-open-link-w"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <ContactForm />
  </div>
</template>

<script>
import ContactForm from "./ContactForm.vue";
import Icon from "./Icon.vue";
import { mapGetters } from "vuex";

export default {
  name: "Incubation",
  components: {
    Icon,
    ContactForm,
  },
  data() {
    return {
      page: null,
      incubators: [],
    };
  },
  computed: {
    ...mapGetters({
      settings: "getSettings",
      isMobile: "getMobile",
      testMode: "getTest",
    }),
    db() {
      return this.$store.state.firebase.firestore;
    },
  },
  methods: {
    getPage() {
      if (this.pageRef) this.pageRef();
      if (this.incubationRef) this.incubationRef();
      this.pageRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}pages`)
        .doc("incubation")
        .onSnapshot((page) => {
          this.page = {
            title: "",
            description: "",
            image: "",
            ...page.data(),
          };
        });
      this.incubationRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}incubations`)
        .orderBy("order")
        .onSnapshot((incubators) => {
          this.incubators = incubators.docs.map((incubator) => incubator.data());
        });
    },
  },
  watch: {
    testMode() {
      this.getPage();
    },
  },
  mounted: function() {
    this.getPage();
  },
  beforeDestroy: function() {
    if (this.pageRef) this.pageRef();
    if (this.incubationRef) this.incubationRef();
  },
};
</script>

<style scoped>
.section {
  margin-bottom: 168px;
}

.section-title {
  position: relative;
  margin-bottom: 48px;
  padding: 0 96px;
}
.section-title.centered {
  text-align: center;
}
.section-title h3 {
  font-size: 64px;
  line-height: 1.166666;
  color: var(--Metallic-Seaweed);
}
.section-title h5 {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.166666;
  margin-top: 16px;
}

.section-image {
  position: relative;
  padding-top: 33.42%;
  margin-bottom: 40px;
}
.section-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.section-footer {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  padding: 0 96px;
}
.section-footer p::after {
  content: '';
  display: block;
  width: 587px;
  max-width: 100%;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 32px auto 40px;
}

.top-section {
  margin-bottom: 96px;
}
.top-section .container {
  position: relative;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(../assets/images/wb_bg_services-header.png);
  border-radius: 12px;
  overflow: hidden;
  padding-top: 53.4%;
}
.top-section .container .content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 64px 96px;
  background: linear-gradient(36deg, rgba(10, 126, 140, 0.80) 13.82%, rgba(10, 126, 140, 0.50) 32.99%, rgba(10, 126, 140, 0.00) 64.59%);
}
.top-section .container .content .logo {
  display: block;
  width: 178px;
  position: absolute;
  top: 64px;
  left: 96px;
}
.top-section .container .content h1 {
  font-size: 128px;
  line-height: 0.86;
  margin-bottom: 16px;
}
.top-section .container .content h3 {
  font-size: 64px;
  margin-bottom: 32px;
  line-height: 1.1625;
  max-width: 920px;
}
.top-section .container .content p {
  font-size: 18px;
}

.text-section .text-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 48px;
}
.text-section .text-container .text-aside {
  width: 42%;
  padding: 0 48px;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.16666666;
}
.text-section .text-container .text-paragraph {
  width: 58%;
  padding: 0 48px;
  font-size: 24px;
  font-weight: 500;
}
.text-section .text-container .text-paragraph p::after {
  content: '';
  display: block;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 40px 0;
}

.incubation .container {
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
}
.incubation .incubator {
  width: calc(50% - 24px);
}
.incubation .incubator img {
  display: block;
  width: 100%;
  border-radius: 12px;
  height: 500px;
  object-fit: cover;
}
.incubation .incubator .incubator-content {
  padding: 32px 32px 0;
}
.incubation .incubator .incubator-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
}
.incubation .incubator .incubator-city {
  font-size: 18px;
  font-weight: 600;
  color: var(--Dark-Blue);
  text-transform: uppercase;
  opacity: 0.46;
}
.incubation .incubator h5 {
  font-size: 36px;
  font-weight: 600;
  color: var(--Metallic-Seaweed);
}
.incubation .incubator p {
  font-size: 24px;
  line-height: 1.166666;
  font-weight: 500;
}
.incubation .incubator .buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.incubation .incubator .buttons .btn {
  margin: 0;
}

@media screen and (max-width: 1280px) {
  .section {
    margin-bottom: 96px;
  }
  .section-title {
    padding: 0 64px;
  }
  .section-title h3 {
    font-size: 48px;
  }
  .top-section .container .content {
    padding: 48px 64px;
  }
  .top-section .container .content .logo {
    width: 200px;
    left: 64px;
    top: 48px;
  }
  .top-section .container .content h1 {
    font-size: 96px;
  }
  .top-section .container .content h3 {
    font-size: 48px;
  }
  .text-section .text-container {
    padding: 0 32px;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    padding: 0 32px;
  }
  .text-section .text-container .text-aside {
    font-size: 28px;
  }
  .text-section .text-container .text-paragraph {
    font-size: 21px;
  }
  .image-text h3 {
    font-size: 48px;
  }
  .image-text .image {
    width: 50%;
  }
  .image-text .content {
    padding: 64px;
  }
  .image-text h5 {
    font-size: 28px;
  }
  .image-text h5 >>> br {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .incubation .incubator img {
    height: 320px;
  }
  .incubation .incubator h5 {
    font-size: 28px;
    margin-bottom: 16px;
  }
  .incubation .incubator p {
    font-size: 20px;
  }
}

@media screen and (max-width: 880px) {
  .section {
    margin-bottom: 96px;
  }
  .section-title {
    padding: 0 48px;
  }
  .section-title h3 {
    font-size: 40px;
  }
  .section-title h5 {
    font-size: 24px;
  }
  .section-footer {
    padding: 0;
  }
  .top-section {
    margin-bottom: 48px;
  }
  .top-section .container {
    min-height: 400px;
  }
  .top-section .container .content {
    padding: 48px;
  }
  .top-section .container .content .logo {
    width: 170px;
    left: 48px;
    top: 48px;
  }
  .top-section .container .content h1 {
    font-size: 72px;
  }
  .top-section .container .content h3 {
    font-size: 32px;
  }
  .text-section .text-container {
    display: block;
    padding: 0 24px;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    width: auto;
  }
  .text-section .text-container .text-aside {
    margin-bottom: 24px;
  }
  .image-text .container {
    display: block;
  }
  .image-text .image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
  }
  .image-text .content {
    padding: 24px 48px 0;
  }
  .incubation .incubator {
    width: 100%;
  }
}

@media screen and (max-width: 580px) {
  .section-title {
    padding: 0;
  }
  .section-footer {
    font-size: 21px;
  }
  .top-section .container .content {
    padding: 32px;
  }
  .top-section .container .content h1 {
    font-size: 48px;
  }
  .top-section .container .content h3 {
    font-size: 32px;
  }
  .text-section .text-container {
    padding: 0;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    padding: 0;
  }
  .text-section .text-container .text-aside {
    font-size: 21px;
  }
  .text-section .text-container .text-paragraph {
    font-size: 18px;
  }
  .image-text h3 {
    font-size: 32px;
  }
  .image-text h3 >>> br {
    display: none;
  }
  .image-text h5 {
    font-size: 24px;
  }
  .image-text p {
    font-size: 21px;
  }
  .image-text .content {
    padding: 24px 0 0;
  }
  .incubation .incubator .buttons .btn {
    min-width: 0;
    padding: 0 24px;
  }
}

@media screen and (max-width: 480px) {
  .incubation .incubator .incubator-content {
    padding: 24px 0 0;
  }
  .top-section .container .content h3 {
    font-size: 24px;
  }
}
</style>
